<template>
  <product-analysis />
</template>

<script>
import ProductAnalysis from '@/components/pages/product/ProductAnalysis'

export default {
  components: {
    ProductAnalysis
  }
}
</script>
