<template>
  <div class="row items-center justify-between">
    <div>
      <PageHeader
        breadcrumb="Product"
        title="Product Analysis"
        subTitle="How Is My Business Doing?"
      />
    </div>

    <div class="q-gutter-md">
      <Button
        variant="primary"
        label="Export"
        size="lg"
        :disabled="!hasData"
        outline
        @click="exportTable"
      >
        <template v-slot:icon>
          <img :src="getIconUrl('icon_export')" width="30" />
        </template>
      </Button>
      <Button
        variant="primary"
        label="Help"
        size="lg"
        outline
        @click="onToggleModal"
      >
        <template v-slot:icon>
          <img :src="getIconUrl('icon_how_to')" width="30" />
        </template>
      </Button>
    </div>
  </div>

  <PageContent>
    <div class="col q-col-gutter-md">
      <div>
        <FilterCard>
          <q-form ref="filterForm" @submit="onFilter" @reset="onReset">
            <div class="row items-start q-col-gutter-md">
              <div class="col-xs-12 col-sm-6 col-md-3">
                <FormDate
                  label="Date From"
                  mask="date"
                  v-model="dateFrom"
                  :value="dateFrom"
                  :rules="[VALIDATION.required]"
                  @input="onSelectFromDate"
                  tabindex="1"
                />
              </div>

              <div class="col-xs-12 col-sm-6 col-md-3">
                <FormDate
                  label="Date To"
                  mask="date"
                  v-model="dateTo"
                  :value="dateTo"
                  :rules="[VALIDATION.required]"
                  @input="onSelectToDate"
                  tabindex="2"
                />
              </div>

              <div class="col-xs-12 col-sm-6 col-md-3">
                <SelectUserStores
                  v-model="store"
                  :values="store"
                  @updateStore="$event => (store = $event)"
                  tabindex="3"
                />
              </div>

              <div class="col-xs-12 col-sm-6 col-md-3">
                <SelectCategories
                  v-model="category"
                  :values="category"
                  withAll
                  @updateStore="$event => (category = $event)"
                  tabindex="4"
                />
              </div>

              <div class="col-xs-12 col-sm-6 col-md-3">
                <SelectBrand
                  v-model="brand"
                  :values="brand"
                  withAll
                  @updateStore="$event => (brand = $event)"
                  tabindex="5"
                />
              </div>

              <div class="col-xs-12 col-sm-6 col-md-3">
                <SelectVendors
                  v-model="vendor"
                  :values="vendor"
                  withAll
                  @updateStore="$event => (vendor = $event)"
                  tabindex="6"
                />
              </div>

              <div class="col-xs-12 col-sm-6 col-md-3">
                <FormSelect
                  label="Top X"
                  v-model="top"
                  :values="top"
                  :options="topOptions"
                  :rules="[VALIDATION.required]"
                  tabindex="7"
                />
              </div>

              <div class="col-xs-12 col-sm-6 col-md-3">
                <FormSelect
                  label="Sort By"
                  v-model="sortBy"
                  :values="sortBy"
                  :options="sortByOptions"
                  :rules="[VALIDATION.required]"
                  tabindex="8"
                />
              </div>

              <div
                class="flex justify-end gap-4 col-xs-12 col-sm-12 col-md-12 text-right"
              >
                <Button
                  variant="primary"
                  label="Reset"
                  icon="refresh"
                  type="reset"
                  outline
                  tabindex="9"
                />
                <Button
                  variant="primary"
                  label="Filter"
                  icon="o_filter_alt"
                  :loading="loadingSales"
                  :disabled="loadingSales"
                  type="submit"
                  tabindex="10"
                />
              </div>
            </div>
          </q-form>
        </FilterCard>
      </div>

      <Loader :loading="loadingSales" />

      <NoDataCard v-if="noData" />

      <div class="col q-col-gutter-md" v-if="hasData">
        <div class="row q-col-gutter-md">
          <div class="col-xs-12 col-sm-6 col-md-3">
            <SimpleCard
              variant="primary"
              title="Number of SKUs"
              :value="FORMAT.toNumber(totalSales.skus)"
            />
          </div>

          <div class="col-xs-12 col-sm-6 col-md-3">
            <SimpleCard
              variant="success"
              title="Number of Vendors"
              :value="FORMAT.toNumber(totalSales.vendors)"
            />
          </div>

          <div class="col-xs-12 col-sm-6 col-md-3">
            <SimpleCard
              variant="info"
              title="Number of Brands"
              :value="FORMAT.toNumber(totalSales.brands)"
            />
          </div>

          <div class="col-xs-12 col-sm-6 col-md-3">
            <SimpleCard
              variant="warning"
              title="Number of Categories"
              :value="FORMAT.toNumber(totalSales.categories)"
            />
          </div>
        </div>

        <div>
          <q-card class="q-pa-lg">
            <q-card-section>
              <HorizontalBarChart
                id="ProductAnalysis"
                type="ProductAnalysis"
                axis="xy"
                :xLabel="sortBy === 'item_quantity' ? 'Quantity' : 'Net Sales'"
                yLabel="SKU Code"
                :xData="sortBy === 'item_quantity' ? 'quantity' : 'netSales'"
                yData="product"
                :data="computedTable"
              />
            </q-card-section>
          </q-card>
        </div>

        <div>
          <Table
            :rows="computedTable"
            :columns="salesTableHeader"
            :loading="loadingTable"
            :limit="limitPage"
            :page="pageNo"
            :total="totalCount"
            @onRequest="onRequest"
            serverSide
          />
        </div>
      </div>
    </div>
  </PageContent>

  <Modal
    :show="showModal"
    header="Help"
    width="700px"
    cancelLabel="Close"
    @close="onToggleModal"
    closeOnly
  >
    <h5 class="q-mb-md">Daily Sales</h5>
    <p>Generate daily sales by date range, store</p>

    <q-separator class="q-my-md" />

    <h6 class="q-mb-md">Filters</h6>
    <p><strong>Date From:</strong> required field</p>
    <p><strong>Date To:</strong> required field</p>
    <p><strong>Store:</strong> required field</p>
  </Modal>
</template>

<script>
import { ref, computed, watch, onBeforeMount } from 'vue'
import PageHeader from '@/components/PageHeader'
import PageContent from '@/components/PageContent'
import { SimpleCard, FilterCard, NoDataCard } from '@/components/cards'
import { FormDate, FormSelect } from '@/components/inputs'
import Button from '@/components/Button'
import Table from '@/components/Table'
import Modal from '@/components/Modal'
import { HorizontalBarChart } from '@/components/charts'
import Loader from '@/components/Loader'
import {
  SelectUserStores,
  SelectVendors,
  SelectCategories,
  SelectBrand
} from '@/components/customs'

import {
  DATE,
  FORMAT,
  VALIDATION,
  EXPORT,
  Toast,
  fetchData,
  getIconUrl
} from '@/tools'

export default {
  name: 'ProductAnalysis',
  components: {
    PageHeader,
    PageContent,
    SimpleCard,
    FilterCard,
    NoDataCard,
    FormDate,
    FormSelect,
    Button,
    Table,
    Modal,
    HorizontalBarChart,
    Loader,
    SelectUserStores,
    SelectVendors,
    SelectCategories,
    SelectBrand
  },
  setup() {
    const { showToast } = Toast()
    const {
      data: dataSummary,
      error: errorSummary,
      loading: loadingSummary,
      post: postSummary
    } = fetchData()

    const {
      data: dataSales,
      error: errorSales,
      loading: loadingSales,
      post: postSales
    } = fetchData()

    const showModal = ref(false)
    const loadingTable = ref(false)
    const isFiltered = ref(false)
    const limitPage = ref(10)
    const pageNo = ref(1)
    const totalCount = ref(0)

    const filterForm = ref(null)
    const dateFrom = ref(DATE.toFriendlyDate(new Date()))
    const dateTo = ref(DATE.toFriendlyDate(new Date()))
    const store = ref([])
    const category = ref([])
    const brand = ref([])
    const vendor = ref(null)
    const top = ref(null)
    const topOptions = ref([
      {
        label: '20',
        value: 20
      },
      {
        label: '50',
        value: 50
      },
      {
        label: '100',
        value: 100
      },
      {
        label: '500',
        value: 500
      },
      {
        label: 'all',
        value: 'all'
      }
    ])
    const sortBy = ref(null)
    const sortByOptions = ref([
      {
        label: 'Sales',
        value: 'net_sales'
      },
      {
        label: 'Quantity',
        value: 'item_quantity'
      }
    ])
    const totalSales = ref({
      sales: 0,
      trxCount: 0,
      basketSize: 0
    })
    const salesTableData = ref(null)

    const salesTableHeader = ref([
      {
        name: 'sku',
        label: 'SKU Code',
        field: 'sku',
        align: 'center',
        sortable: true
      },
      {
        name: 'barcode',
        label: 'Barcode',
        field: 'barcode',
        align: 'center',
        sortable: true
      },
      {
        name: 'product',
        label: 'Product Name',
        field: 'product',
        align: 'left',
        sortable: true
      },
      {
        name: 'vendor',
        label: 'Vendor',
        field: 'vendor',
        align: 'left',
        sortable: true
      },
      {
        name: 'quantity',
        label: 'Item Quantity',
        field: row => FORMAT.toNumber(row.quantity),
        align: 'center',
        sortable: true
      },
      {
        name: 'netSales',
        label: 'Net Sales',
        field: row => FORMAT.toCurrency(row.netSales),
        align: 'center',
        sortable: true
      }
    ])

    const options = ref([
      {
        label: 'Google',
        value: 'google'
      },
      {
        label: 'Facebook',
        value: 'facebook'
      },
      {
        label: 'Twitter',
        value: 'twitter'
      },
      {
        label: 'Apple',
        value: 'apple'
      },
      {
        label: 'Oracle',
        value: 'oracle'
      }
    ])

    const hasData = computed(
      () => salesTableData.value && salesTableData.value.length > 0
    )

    const noData = computed(
      () => salesTableData.value && salesTableData.value.length === 0
    )

    const sortedTable = computed(() => {
      let sortName

      switch (sortBy.value) {
        case 'net_sales': {
          sortName = 'netSales'
          break
        }
        case 'item_quantity': {
          sortName = 'quantity'
          break
        }
        default: {
          sortName = 'sku'
        }
      }

      return sortName
    })

    const computedTable = computed(() => {
      if (top?.value !== 'all') {
        return salesTableData.value.slice(0, top.value)
      } else {
        return salesTableData.value
      }
    })

    const onToggleModal = () => {
      showModal.value = !showModal.value
    }

    const onFilter = () => {
      if (!isFiltered.value) {
        limitPage.value = 10
        pageNo.value = 1
        totalCount.value = 0
      }

      filterForm.value.validate().then(success => {
        if (success) {
          const payload = {
            pagination: {
              page: pageNo.value
            },
            filters: {
              date_from: DATE.toFriendlyDate(dateFrom.value),
              date_to: DATE.toFriendlyDate(dateTo.value),
              stores: store.value,
              sort_by: sortBy.value
            }
          }

          if (top.value && top.value !== 'all') {
            payload.pagination.per_page = top.value
          }

          if (vendor.value && vendor.value !== 'all') {
            payload.filters.vendor = vendor.value
          }

          if (category.value && category.value !== 'all') {
            payload.filters.category = category.value
          }

          if (brand.value && brand.value !== 'all') {
            payload.filters.brand = brand.value
          }

          postSummary('v1/product/analysis/summary', payload)
          postSales('v1/product/analysis/detailed', payload)
        } else {
          showToast('Please fill all the required fields', 'info')
        }
      })
    }

    const onReset = () => {
      filterForm.value.resetValidation()
      dateFrom.value = DATE.toFriendlyDate(new Date())
      dateTo.value = DATE.toFriendlyDate(new Date())
      store.value = []
      salesTableData.value = null
      limitPage.value = 10
      pageNo.value = 1
      totalCount.value = 0
    }

    const onSelectFromDate = data => {
      dateFrom.value = data
    }

    const onSelectToDate = data => {
      dateTo.value = data
    }

    const exportTable = () => {
      EXPORT.exportToExcel(
        salesTableHeader.value,
        computedTable.value,
        'product_analysis'
      )
    }

    const onRequest = props => {
      const { page, rowsPerPage } = props.pagination
      isFiltered.value = true
      pageNo.value = page
      limitPage.value = rowsPerPage

      onFilter()
    }

    watch([sortBy], () => {
      if (salesTableData?.value?.length > 0)
        salesTableData.value = salesTableData.value.sort((a, b) =>
          a[sortedTable.value] > b[sortedTable.value] ? -1 : 1
        )
    })

    onBeforeMount(() => {
      watch([dataSummary, errorSummary, loadingSummary], () => {
        if (loadingSummary.value) {
          if (isFiltered.value) {
            loadingTable.value = true
          } else {
            totalSales.value = {
              skus: 0,
              vendors: 0,
              brands: 0,
              categories: 0
            }
          }
        } else {
          if (errorSummary?.value) {
            showToast('There was a problem fetching sales summary.', 'danger')
          } else if (dataSummary?.value) {
            totalSales.value = {
              skus: dataSummary?.value?.skus,
              vendors: dataSummary?.value?.vendors,
              brands: dataSummary?.value?.brands,
              categories: dataSummary?.value?.categories
            }
          }
        }
      })

      watch([dataSales, errorSales, loadingSales], () => {
        if (loadingSales.value) {
          if (isFiltered.value) {
            loadingTable.value = true
          } else {
            salesTableData.value = null
          }
        } else {
          if (errorSales?.value) {
            showToast('There was a problem fetching sales.', 'danger')
          } else if (dataSales?.value) {
            totalCount.value =
              top.value === 'all'
                ? dataSales?.value?.total
                : dataSales?.value?.data?.length

            salesTableData.value =
              dataSales?.value?.data?.map(item => ({
                sku: item.sku_id,
                barcode: item.barcode,
                product: item.sku_desc,
                vendor: item.supplier_name,
                quantity: item.item_quantity,
                netSales: item.net_sales
              })) ?? []

            salesTableData.value = salesTableData.value.sort((a, b) =>
              a[sortedTable.value] > b[sortedTable.value] ? -1 : 1
            )
          } else {
            salesTableData.value = []
          }

          isFiltered.value = false
          loadingTable.value = false
        }
      })
    })

    return {
      showModal,
      loadingTable,
      isFiltered,
      limitPage,
      pageNo,
      totalCount,
      filterForm,
      dateFrom,
      dateTo,
      store,
      category,
      brand,
      vendor,
      top,
      topOptions,
      sortBy,
      sortByOptions,
      salesTableHeader,
      salesTableData,
      options,
      totalSales,
      loadingSales,
      FORMAT,
      VALIDATION,
      getIconUrl,
      hasData,
      noData,
      computedTable,
      onToggleModal,
      onFilter,
      onReset,
      onSelectFromDate,
      onSelectToDate,
      exportTable,
      onRequest
    }
  }
}
</script>
